.content {
    flex: 0 1 auto;
    width: 100%;
    font-family: $main-font;
    font-size: $main-size;
    line-height: $line-height;
    color: $black;

    &--left {
        flex-basis: 818px;
    }

    &__breadcrumbs {
        color: #747474;
        font-size: 11px;
    }

    &__breadcrumbs-item {
        a {
            color: $green;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__breadcrumbs-sep {
        padding: 0 2px;
    }

    &__header {
        margin: 25px 0 20px 0;
    }

    &__main-header {
        font-size: 24px;
        font-weight: normal;
    }

    &__date {
        color: #888;
        font-size: 12px;
    }

    &__text {
        p {
            margin: 0;
        }

        a {
            color: $green;

            &:hover {
                text-decoration: none;
            }
        }

        img {
            max-width: 100%;
            height: auto;
            margin: 10px 0;
        }

        table {
            display: block;
            border: 1px solid $green;
            border-collapse: collapse;
            border-width: 1px 0 1px 0;
            font-size: 12px;
            margin: 10px 0;
            overflow: auto;

            tr {
                border-bottom: 1px solid #eaeaea;

                &:last-child {
                    border-bottom: 0;
                }
            }
            
            td {
                padding: 12px 8px;
            }
        }
    }

    &__item {
        margin-bottom: 20px;
    }

    &__item-article {
        margin-bottom: 10px;
    }

    &__item-title {
        color: #73a800;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }

    &__category-items {
        -js-display: flex; 
        display: flex;
        flex-wrap: wrap;
    }

    &__category-item {
        flex-basis: 250px;
        border: 1px solid #e8e8e8;
        color: #3d3d3d;
        display: block;
        margin: 0 5px 15px 0;
        padding: 3px;
        height: 291px;
        text-align: center;
        overflow: hidden;

        &--home {
            flex-basis: 259px;
        }

        a {
            color: $black;
            text-decoration: none;

            &:hover {
                color: $green;
            }
        }
    }

    &__category-item-img {
        display: block;
        margin: 0 auto;
        height: 210px;
        width: 230px;
        padding: 2px 10px 0px;
        line-height: 210px;
        img {
            width: 70%;
            height: auto;
            max-height: 100%;
            vertical-align: middle;
        }
    }

    &__category-item-text {
        display: block;
    }

    &__category-item-header {
        font-size: 14px;
        font-weight: bold;
        display: block;
    }

    &__category-item-desc {
        font-size: 12px;
        line-height: 14px;
        display: block;
    }

    &__button-wrapper {
        text-align: center;
        margin: 0 0 20px;
    }

    &__button {
        font-size: 16px;
        margin: 15px 0;
        padding: 5px 25px;
        width: 100px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .content {
        flex: 1 1 auto;
        &__category-item {
            flex-basis: calc(48% - 6px);
            border: 1px solid #e8e8e8;
            color: #3d3d3d;
            display: block;
            margin: 0 1% 15px 0;
            padding: 3px;
            height: 291px;
            text-align: center;
            overflow: hidden;

            a {
                color: $black;
                text-decoration: none;

                &:hover {
                    color: $green;
                }
            }
        }
        &__category-item-img {
            display: block;
            margin: 0 auto;
            height: 210px;
            width: auto;
            padding: 2px 10px 0px;
            background-size: cover;
            img {
                width: 70%;
                height: auto;
                max-height: 100%;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .content {
        flex: 1 1 auto;
        padding-bottom: 40px;

        &__category-item {
            flex-basis: 100%;
            border: 1px solid #e8e8e8;
            color: #3d3d3d;
            display: block;
            margin: 0 5px 15px 0;
            padding: 3px;
            height: 291px;
            text-align: center;
            overflow: hidden;

            a {
                color: $black;
                text-decoration: none;

                &:hover {
                    color: $green;
                }
            }
        }
    }
}