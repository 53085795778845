.wide-banner {
    max-width: 1200px;
    width: 100%;

    img {
        width: 100%;
        height: auto;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .wide-banner {
        max-width: 1200px;
        width: 96%;

        img {
            width: 100%;
            height: auto;
        }
    }
}