.btn {
    background: -webkit-linear-gradient(#addb37 0%, #6fa303 100%);
    background: -o-linear-gradient(#addb37 0%, #6fa303 100%);
    background: linear-gradient(#addb37 0%, #6fa303 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#addb37', endColorstr='#6fa303',GradientType=0 );
    border: 1px solid #91cc12;
    border-radius: 3px;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    height: 30px;
    line-height: 30px;
    padding: 0 20px;
    position: relative;
    text-decoration: none;
    text-shadow: 0 1px 0 rgba(0,0,0,0.3);
    width: 200px;
    text-align: center;

    &:hover {
        background: -webkit-linear-gradient(#c3f544 0%, #78b103 100%);
        background: -o-linear-gradient(#c3f544 0%, #78b103 100%);
        background: linear-gradient(#c3f544 0%, #78b103 100%);
    }

    &:active {
        background: -webkit-linear-gradient(#6fa303 0%, #addb37 100%);
        background: -o-linear-gradient(#6fa303 0%, #addb37 100%);
        background: linear-gradient(#6fa303 0%, #addb37 100%);
    }
}