.page-header {
    font-family: $main-font;
    font-size: $main-size;
    line-height: $line-height;
    -js-display: flex; 
    display: flex;
    padding-bottom: 20px;

    &__col {
        flex-basis: 270px;
    }

    &__logo-menu {
        flex-basis: 540px;
    }

    &__add-menu {
        flex-basis: 350px;
        padding-top: 19px;
        line-height: 11px;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            width: 105px;

            li {
                border-bottom: 1px solid #d8d8d8;
                padding: 7px 0;

                &:last-child {
                    border-bottom: none;
                }

                a {
                    color: #737373;
                    font-size: 11px;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &__contacts {
        flex-basis: 200px;
        padding-top: 21px;
    }

    &__contact-phone {
        display: none;
        position: relative;
        padding-left: 33px;

        &::before {
            content: "";
            width: 23px;
            height: 23px;
            position: absolute;
            left: 0;
            background: url('../img/phone-icons.png') no-repeat;
            background-size: 23px;
            background-position: 0 0;
        }

        &--active {
            display: block;
        }
    }


    &__contacts-main-phone {
        display: block;
        font-size: 20px;
        font-weight: bold;
        line-height: 18px;
    }



    &__contacts-add-phone {
        display: block;
        color: #7b7b7b;
        font-size: 15px;
        font-weight: bold;
        margin-top: 5px;
    }

    &__contact-regions {
        position: relative;
        padding: 5px 0 0 33px;
        &::before {
            content: "";
            width: 23px;
            height: 28px;
            position: absolute;
            left: 0;
            background: url('../img/phone-icons.png') no-repeat;
            background-size: 23px;
            background-position: 0 -41px;
        }
    }

    &__contact-region {
        display: block;
        line-height: 14px;
        font-weight: bold;

        a {
            font-size: 11px;
            font-weight: bold;
            text-decoration: none;  
            color: $green;

            &:hover {
                border-bottom: 1px dashed $green;
            }
        }

        &--active {
            a {
                color: $black;
                font-size: 13px;
                cursor: default;

                &:hover {
                    border-bottom: none;
                }
            }
        }

    }
    &__logo {
        margin: 21px 0 35px 0;
    }

    &__main-menu {
        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            -js-display: flex; 
            display: flex;

            li {
                border-right: 1px solid #d8d8d8;
                padding: 0 9px;

                &:first-child{
                    padding-left: 0;
                }

                &:last-child{
                    border-right: none;
                }

                &.active {
                    background-color: $green;
                    border-right: 0;
                    padding-left: 9px;

                    a {
                        color: $white;
                    }
                }

                a {
                    color: $green;
                    font-size: 11px;
                    font-weight: bold;
                    text-decoration: none;
                    text-transform: uppercase;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

    }

    &__hamburger {
        display: none;
        width: 70px;
        height: 70px;
        background: url('../img/gamburger.png') no-repeat; 
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .page-header {
        position: relative;
        height: 150px;

        &__logo-menu {
            flex-basis: 70%;
        }

        &__add-menu {
            position: absolute;
            bottom: 20px;
            left: 2%;

            ul {
                -js-display: flex; 
                display: flex;
                width: 100%;

                li {
                    border-bottom: 0;
                    padding: 0 10px;
                    border-right: 1px solid #d8d8d8;

                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        border-right: none;
                    }
                }
            }
        }

        &__contacts {
            flex-basis: 30%;
        }
    }
}

@media screen and (max-width: 767px) {
    .page-header {
        flex-direction: column;
        height: auto;

        &__logo-menu {
            flex-basis: 100%;
        }

        &__logo {
            margin: 21px 0 20px 0;
        }

        &__main-menu {
            display: none;
            position: absolute;
            left: 5%;
            top: 80px;
            background-color: $white;
            z-index: 10;
            width: 90%;
            padding: 0 0 20px;

            ul {
                flex-direction: column;

                li {
                    border-right: 0px;
                    padding-left: 0px;
                }
            }

        }

        &__add-menu {
            display: none;
        }

        &__contacts {
            flex-basis: 100%;
            padding-top: 0;
        }

        &__mobile-button {
            display: block;
            position: absolute;
            top: 22px;
            right: 5%;
            width: 40px;
            height: 33px;
            background: url('../img/hamburger-green.png') no-repeat;

            &--close {
                width: 40px;
                height: 40px;
                background: url('../img/close-green.png') no-repeat; 
            }
        }
    }
}